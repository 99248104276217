import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  /*public listaNav: any
  public faq: String*/

  constructor(private config:ConfigService) { }

  ngOnInit() 
  {
    /*this.listaNav = this.config.getConfig().nav.menuNav
    this.faq = this.config.getConfig().nav.faq*/
  }

}
