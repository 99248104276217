import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.css']
})
export class TechnologiesComponent implements OnInit {

  public tit: any
  public imgang: any
  public imgnode: any
  public imgcss: any
  public imgionic: any
  public imgios: any
  public imgmondb: any
  public imgand: any
  public imghtml: any
  public imglinux: any
  public imgmysql: any
  public imgphp: any
  public imgswf: any
  public imgwoo: any
  public imgmic: any
  public imgwp: any

  constructor(private config:ConfigService) { }

  ngOnInit()
    {
      this.tit = this.config.getConfig().technologies.title
      this.imgang = this.config.getConfig().technologies.imageangular
      this.imgnode = this.config.getConfig().technologies.imagenodejs
      this.imgcss = this.config.getConfig().technologies.imagecss
      this.imgionic = this.config.getConfig().technologies.imageionic
      this.imgios = this.config.getConfig().technologies.imageios
      this.imgmondb = this.config.getConfig().technologies.imagemongodb
      this.imgand = this.config.getConfig().technologies.imageandroid
      this.imghtml = this.config.getConfig().technologies.imagehtml
      this.imglinux = this.config.getConfig().technologies.imagelinux
      this.imgmysql = this.config.getConfig().technologies.imagemysql
      this.imgphp = this.config.getConfig().technologies.imagephp
      this.imgswf = this.config.getConfig().technologies.imageswift
      this.imgwoo = this.config.getConfig().technologies.imagewoocommerce
      this.imgmic = this.config.getConfig().technologies.imagemicrosoft
      this.imgwp = this.config.getConfig().technologies.imagewordpress
    }

}
