import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { FormGroup ,FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  public subscribe: any
  public describe: any
  public email:any
  public subscriptionform : FormGroup
  public emailsuscription:AbstractControl

  constructor(private config:ConfigService,private formBuilder: FormBuilder, private contactService: ContactService) 
  {
     this.subscriptionform = this.formBuilder.group({
      emailsuscription: ['', Validators.compose( [ Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]) ],
      })

      this.emailsuscription = this.subscriptionform.controls['emailsuscription']
    }

  ngOnInit()
  {
    this.subscribe = this.config.getConfig().subscription.title
    this.describe = this.config.getConfig().subscription.description
    this.email = this.config.getConfig().subscription.placeholdermail
  }


  get emailValidateSubscription()
  {
    return this.subscriptionform.get('emailsuscription')
  } 

  onSubmitSuscription()
  {
    this.contactService.registerSubscription(this.subscriptionform.value)
          .subscribe( (res:any) => {
            if(res)
            {
              this.resetForm()
              this.swalMessage('Datos enviados satisfactoriamente', '', 'success')
           }
           else
           {
              this.swalMessage('Ocurrio un error al enviar datos ', '', 'error')  
           }
     })
  }

  resetForm() 
  {
    this.subscriptionform.reset()
  }

  swalMessage(title:any, label:any, type:any)
  {
    Swal.fire(title, label, type)
  }


}
