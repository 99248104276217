import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { FormGroup ,FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import Swal from 'sweetalert2'


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public mtitle: any
  public subform: any
  public subdata: any
  public locate: any
  public mail: any
  public tel: any
  public cell: any
  public nameform: any
  public mailform: any
  public subjectform: any
  public messageform: any
  public btn: any
  public contactform : FormGroup
  public name:AbstractControl
  public email:AbstractControl
  public msgsubject:AbstractControl
  public message:AbstractControl

  constructor(private config:ConfigService,private formBuilder: FormBuilder, private contactService:  ContactService)
  { 
      this.contactform = this.formBuilder.group({
        name: ['', Validators.compose( [Validators.required, Validators.maxLength(20), Validators.pattern('[a-zA-Z ]*')] )],
        email: ['', Validators.compose( [ Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]) ],
        msgsubject : ['', Validators.required ],
        message: ['', Validators.required ]
      })

      this.name = this.contactform.controls['name']
      this.email = this.contactform.controls['email']
      this.msgsubject = this.contactform.controls['msgsubject']
      this.message = this.contactform.controls['message']
  }

  ngOnInit()
  {
      this.mtitle       = this.config.getConfig().contact.title
      this.subform      = this.config.getConfig().contact.subtitleform
      this.subdata      = this.config.getConfig().contact.subtitledata
      this.locate       = this.config.getConfig().contact.addres
      this.mail         = this.config.getConfig().contact.email
      this.tel          = this.config.getConfig().contact.phone
      this.cell         = this.config.getConfig().contact.mobile
      this.nameform     = this.config.getConfig().contact.inputname
      this.mailform     = this.config.getConfig().contact.inputmail
      this.subjectform  = this.config.getConfig().contact.inputsubject
      this.messageform  = this.config.getConfig().contact.inputmessage
      this.btn          = this.config.getConfig().contact.button
  }

  get emailValidate()
  {
    return this.contactform.get('email');
  } 
  
  onSubmitContact()
  {
     this.contactService.registerContact(this.contactform.value)
          .subscribe( (res:any) => {
            if(res){
              this.resetForm()
              this.swalMessage('Datos enviados satisfactoriamente', '', 'success')
            }else{
              this.swalMessage('Ocurrio un error al enviar datos ', '', 'error')  
            }
          })
  }

  resetForm() 
  {
    this.contactform.reset()
  }

  swalMessage(title:any, label:any, type:any)
  {
    Swal.fire(title, label, type)
  }


}
