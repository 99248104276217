import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {


  constructor(private config:ConfigService) { }

  ngOnInit()
    {
      
    }

}
