import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public logo: any
  public welcome: any
  public slogan: any
  public imgmobile: any
  public know: any
  public listaNav: any
  public faq: any
  public listNavR: any

  constructor(private config:ConfigService) { }

  ngOnInit() 
  {
    this.logo = this.config.getConfig().logos.logocompany
    this.welcome = this.config.getConfig().header.textwelcome
    this.slogan = this.config.getConfig().header.lema
    this.imgmobile = this.config.getConfig().header.imghero
    this.know = this.config.getConfig().header.knowmore
    this.listaNav = this.config.getConfig().nav.menuNav
    this.faq = this.config.getConfig().nav.faq
    //this.listNavR = this.config.getConfig().navresponsive.menuNav    
  }

}
