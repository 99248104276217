export const configuration = {
  bussinesServer:{
    url:'http://localhost:4000'
  },
  logos:{
    logocompany:'../assets/img/logo-sigma7-cabecera.png', 
  },
  nav: {
    menuNav:
    [
      {
        home: 'HOME',
        services: 'SERVICIOS',
        projects: 'PROYECTOS',
        team: 'EQUIPO',
        contact: 'CONTACTO',
      }
    ],
    faq: 'LOGIN'
    },
  header:{
    textwelcome:'APUNTAMOS A LA INNOVACIÓN PARA CONTRIBUIR AL DESARROLLO DE LA REGIÓN',
    lema:'Somos Especialistas en la Prestación de Servicios en Tecnología con un Alto Componente de Investigación, Desarrollo e Innovación (I+D+i)',
    knowmore:'Conoce Más',
    imghero:'../assets/img/hero2_mod_min.png'
  },
  services: {
    mainname: 'SERVICIOS',
    serv1:
      [
        {
          icon: 'fas fa-cloud',
          subtitle: 'ENERGÍAS ALTERNATIVAS Y PROYECTOS SOSTENIBLES',
        }
      ],
    dataText1:
      [
        {
          text1: '• Sistemas solares y eólicos.',
          text2: '• Aprovechamiento y potabilización de aguas lluvias.',
          text3: '• Aprovechamiento y manejo de aguas grises.'
        }
      ],
    serv2:
      [
        {
          icon: 'fas fa-fingerprint',
          subtitle: 'SEGURIDAD ELECTRÓNICA',
        }
      ],
    dataText2:
      [
        {
          text1: '• Sistemas de intrusion.',
          text2: '• Sistemas CCTV',
          text3: '• Sistemas de control y acceso.'
        }
      ],
    serv3:
    [
      {
        icon: 'fas fa-laptop-code',
        subtitle: 'SOFTWARE Y REDES',
      }
    ],
    dataText3:
    [
      {
        text1: '• Infraestructura de redes y cableado estructurado.',
        text2: '• Telefonía IP.',
        text3: '• Desarrollo de software.',
        text4: '• Análisis de seguridad informática.',
        text5: '• Asesorías y capacitaciones.'
      }  
    ],
    serv4:
      [
        {
          icon: 'fas fa-cog',
          subtitle: 'AUTOMATIZACIÓN INDUSTRIAL',
        }
      ],
    dataText4:
      [
        {
          text1: '• Alquiler y mantenimiento de equipos industriales.',
          text2: '• Diseño, instalación y mantenimiento de redes eléctricas.',
          text3: '• Instalación y mantenimiento de sistemas de iluminación.',
          text4: '• Instalación y mantenimiento de sistemas de audio.',
          text5: '• Instalación y mantenimiento de sistemas de climatización.'
        }
      ],
    },
    proyects:{
      title:'PROYECTOS',
      image:'../assets/img/intro-mobile_qs.png'
    },
    technologies: {
      title:'TECNOLOGÍAS',
      imageangular: '../assets/img/technologies/angular.svg',
      imagenodejs: '../assets/img/technologies/nodejs.svg',
      imagecss: '../assets/img/technologies/css.svg',
      imageionic: '../assets/img/technologies/ionic.svg',
      imageios: '../assets/img/technologies/ios.svg',
      imagemongodb: '../assets/img/technologies/mongodb.svg',
      imageandroid: '../assets/img/technologies/android.svg',
      imagehtml: '../assets/img/technologies/html.svg',
      imagelinux: '../assets/img/technologies/linux.svg',
      imagemysql: '../assets/img/technologies/mysql.svg',
      imagephp: '../assets/img/technologies/php.svg',
      imageswift: '../assets/img/technologies/swift.svg',
      imagewoocommerce: '../assets/img/technologies/woocommerce.svg',
      imagemicrosoft: '../assets/img/technologies/microsoft.svg',
      imagewordpress: '../assets/img/technologies/wordpress.svg',
    },
    team:{
      title:'EQUIPO',
      profiles:
      [
        {
          imgprofile:'../assets/img/team/team-01.jpg',
          iconfb:'lni-facebook-filled',
          icontt:'lni-twitter-filled',
          iconig:'lni-instagram-filled',
          nameperson:'Juan Manuel Hurtado',
          position:'Gerente General'        
        },
        {
          imgprofile:'../assets/img/team/team-02.jpg',
          iconfb:'lni-facebook-filled',
          icontt:'lni-twitter-filled',
          iconig:'lni-instagram-filled',
          nameperson:'Manuel Alberto Gonzales',
          position:'Subgerente TI'        
        },
        {
          imgprofile:'../assets/img/team/team-03.jpg',
          iconfb:'lni-facebook-filled',
          icontt:'lni-twitter-filled',
          iconig:'lni-instagram-filled',
          nameperson:'Jhon Javier Rentería',
          position:'Líder de Desarrollo'        
        }
      ]
    },
    brands:{
      title: 'CLIENTES',
      imgunpa: '../assets/img/clients/logo_unpa_gris.png',
      imgmejia: '../assets/img/clients/logo-Tmejia.png',
      imgalcaldia: '../assets/img/clients/logo_alcaldiabun_gris.png',
      imgspb: '../assets/img/clients/logo-spb-gris.png',
      imgsolinter: '../assets/img/clients/logo-solinter_gris.png',
      imgagecolda: '../assets/img/clients/logo_agecolda_gris.png',
    },
    subscription:{
      title: 'Suscríbete a nuestras ofertas',
      description:'Recibirás ofertas exclusivas de nuestros servicios en tu email. Podrás darte de baja cuando quieras.',
      placeholdermail:'Correo electrónico'
    },
    contact:{
      title: 'CONTACTO',
      subtitleform:'Formulario de Contacto',
      inputname:'Nombre',
      inputmail:'E-mail',
      inputsubject:'Asunto',
      inputmessage:'Mensaje',
      button:'Enviar Mensaje',
      subtitledata: 'Contáctanos',
      addres:'Cra 52 # 4B-25 Barrio Transformación | Buenaventura, Valle del Cauca - Colombia',
      email:'comercial@sigma7.com.co',
      phone:'(+57) (2) 241 60801',
      mobile:'(+57) 315 894 8171'
    },
    footer: {
      company:'Empresa',
      aboutus:'Acerca de',
      find:'Encuentranos en',
      copy:'Copyright © 2019 Sigma7 SAS. Todos los derechos reservados.',
      linkfb:'',
    },
    resetComponent:{
      titlte:'Sigma7 S.A.S',
      placeholderUser:'Usuario',
      textreset:'Recuperar contraseña' 
     },
    registerComponent:{
      placeholderDocument:'Número de documento',
      placeholderType:'Tipo',
      placeholderName:'Nombres',
      placeholderSurName:'Apellidos',
      placeholderGender:'Genero',
      placeholderAdress:'Dirección',
      placeholderPhone:'Celular',
      placeholderEmail:'Correo',
      placeholderUser:'Usuario',
      placeholderPassword:'Contraseña',
      placeholderRepeatPassword:'Repetir Contraseña',
      accept:'Aceptar',
      yes:'Si',
      no:'No'
    }
  }