import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  readonly URL_API = this.config.getConfig().bussinesServer.url

  constructor(private http: HttpClient, private config:ConfigService)
  {
  }

  registerSubscription(nameProduct: any) {
    return this.http.post(this.URL_API + "/contact/subscription", nameProduct)
  }
  
  registerContact(contact: any) {
    return this.http.post(this.URL_API + "/contact/create", contact)
  }
}
