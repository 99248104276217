import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-navresponsive',
  templateUrl: './navresponsive.component.html',
  styleUrls: ['./navresponsive.component.css']
})
export class NavresponsiveComponent implements OnInit {

  /*public listNavR: any*/
  public faqR: any

  constructor(private config:ConfigService) { }

  ngOnInit()
    {
    /*this.listNavR = this.config.getConfig().nav.menuNav*/
    this.faqR = this.config.getConfig().nav.faq
    }

}
