import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public logofooter : any
  public sloganfooter: any
  public comp: any
  public about:any
  public findus : any
  public fb : any
  public copyright : any

  constructor(private config:ConfigService) { }

  ngOnInit()
  {
    this.logofooter   = this.config.getConfig().logos.logocompany
    this.sloganfooter = this.config.getConfig().header.lema
    this.comp         = this.config.getConfig().footer.company
    this.about        = this.config.getConfig().footer.aboutus
    this.findus       = this.config.getConfig().footer.find
    this.fb           = this.config.getConfig().footer.linkfb
    this.copyright    = this.config.getConfig().footer.copy
  }

}
