import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { ServicesComponent } from './components/services/services.component';
import { FeatureComponent } from './components/feature/feature.component';
import { TeamComponent } from './components/team/team.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ContactComponent } from './components/contact/contact.component';
import { ConfigService } from './services/config-service';
import { MessageService } from './services/message.service';
import { NavComponent } from './components/nav/nav.component';
import { NavresponsiveComponent } from './components/navresponsive/navresponsive.component';
import { FooterComponent } from './components/footer/footer.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { BrandsComponent } from './components/brands/brands.component';
import { ProyectsComponent } from './components/proyects/proyects.component';
import { TechnologiesComponent } from './components/technologies/technologies.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './services/contact.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ServicesComponent,
    FeatureComponent,
    TeamComponent,
    TestimonialComponent,
    PricingComponent,
    ContactComponent,
    NavComponent,
    NavresponsiveComponent,
    FooterComponent,
    SubscriptionComponent,
    BrandsComponent,
    ProyectsComponent,
    TechnologiesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    ConfigService,
    ContactService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
