import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.css']
})
export class ProyectsComponent implements OnInit {

  public tit : any
  public img : any

  constructor(private config:ConfigService) { }

  ngOnInit()
    {
    this.tit = this.config.getConfig().proyects.title
    this.img = this.config.getConfig().proyects.image
    }

}
