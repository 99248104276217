import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {

  public tit: any
  public imgu: any
  public imej: any
  public ialc: any
  public ispb: any
  public isol: any
  public iage: any
  

  constructor(private config:ConfigService) { }


    ngOnInit()
      {
      this.tit = this.config.getConfig().brands.title
      this.imgu = this.config.getConfig().brands.imgunpa
      this.imej = this.config.getConfig().brands.imgmejia
      this.ialc = this.config.getConfig().brands.imgalcaldia
      this.ispb = this.config.getConfig().brands.imgspb
      this.isol = this.config.getConfig().brands.imgsolinter
      this.iage = this.config.getConfig().brands.imgagecolda
      }

}
