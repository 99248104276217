import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  public tit: any
  public profilesteam: any

  constructor(private config:ConfigService) { }

  ngOnInit()
    {
      this.tit = this.config.getConfig().team.title
      this.profilesteam = this.config.getConfig().team.profiles
    }

}
