import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config-service';
import { config } from 'rxjs';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  public mname: any
  public dservs1: any
  public list1: any
  public dservs2: any
  public list2: any
  public dservs3: any
  public list3: any
  public dservs4: any
  public list4: any

  constructor(private config:ConfigService) { }

  ngOnInit()
  {
    this.mname = this.config.getConfig().services.mainname
    this.dservs1 = this.config.getConfig().services.serv1
    this.list1 = this.config.getConfig().services.dataText1
    this.dservs2 = this.config.getConfig().services.serv2
    this.list2 = this.config.getConfig().services.dataText2
    this.dservs3 = this.config.getConfig().services.serv3
    this.list3 = this.config.getConfig().services.dataText3
    this.dservs4 = this.config.getConfig().services.serv4
    this.list4 = this.config.getConfig().services.dataText4
  }

}
